<template>
  <div>
    <v-container>
      <h2 class="text-h5 mb-4">Announcement Bar</h2>
      <v-card class="mb-4" style="padding: 20px">
        <v-row>
          <v-col md="3">
            <v-text-field
              v-model="filters.search_keyword"
              label="Search Keyword"
              outlined
              @change="fetchAnnouncements()"
              dense
              placeholder="Search Keyword"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col md="3">
            <v-select
              style="margin-right: 10px"
              v-model="filters.status"
              dense
              :items="[{name : 'All' , value : ''}, { name : 'Active', value : 1}, {name : 'In-Active' , value : 0}]"
              item-text="name"
              item-value="value"
              @change="fetchAnnouncements()"
              label="Status"
              outlined
            >
            </v-select>
          </v-col>
          <v-col md="6" style="text-align: right;">
            <v-btn
              color="primary"
              @click="openCreateDialog"
              class="tet-white mr-2"
              small
            >Create New Announcement</v-btn>
          </v-col>
        </v-row>
      </v-card>

      <v-data-table :headers="headers" :items="announcements.data" class="elevation-1" disable-pagination hide-default-footer>
        <template v-slot:body="{ items }">
          <tbody v-if="!loading_data">
            <tr v-for="item in items" :key="item.id">
              <td>{{ item.en_title }}</td>
              <td>{{ item.en_primary_message }}</td>
              <td>{{ item.en_secondary_message }}</td>
              <td>
                <v-chip small class="ma-2" color="green" text-color="white" v-if="item.status == '1'">Active</v-chip>
                <v-chip small class="ma-2" color="red" text-color="white" v-else>Inactive</v-chip>
              </td>
              <td>
                <v-btn fab small color="primary darken-1" class="table-action--button" @click="openEditDialog(item.id)">
                  <v-icon>{{ mdiSquareEditOutline }}</v-icon>
                </v-btn>
                <v-btn fab small color="primary darken-1" class="table-action--button" @click="openDeleteDialog(item.id)">
                  <v-icon>{{ mdiTrashCanOutline }}</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="5" class="text-center" style="padding: 80px 0px">
                <img :src="require('@/assets/images/misc/loading.gif')" alt="" width="100px" />
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <v-pagination
        v-model="announcements.current_page"
        class="my-4"
        @input="fetchAnnouncements()"
        :length="announcements.last_page"
        :total-visible="7"
      ></v-pagination>


      <!-- Create/Edit Dialog -->
      <v-dialog v-model="create_dialog" width="1000" :retain-focus="false">
        <v-card>
          <v-card-title class="text-h6 grey lighten-2">
            {{ edit_mode ? 'Edit Announcement' : 'Create New Announcement' }}
          </v-card-title>

          <v-card-text>
            <div style="padding: 20px">
              <v-select
                v-model="announcement_data.status"
                dense
                :items="content_status"
                label="Status"
                item-text="name"
                item-value="value"
                outlined
              ></v-select>
            </div>

            <!-- Language Tabs -->
            <v-tabs v-model="activeTab" background-color="primary" dark>
              <v-tab v-for="(lang, index) in languages" :key="index">{{ lang.label }}</v-tab>
            </v-tabs>

            <v-tabs-items v-model="activeTab">
              <v-tab-item v-for="(lang, index) in languages" :key="index">
                <v-card flat>
                  <div style="padding: 20px">
                    <v-text-field
                      v-model="announcement_data[`${lang.prefix}_title`]"
                      :label="`${lang.label} Title`"
                      outlined
                    ></v-text-field>
                    <v-textarea
                      v-model="announcement_data[`${lang.prefix}_primary_message`]"
                      :label="`${lang.label} Primary Message`"
                      outlined
                      rows="3"
                    ></v-textarea>
                    <v-textarea
                      v-model="announcement_data[`${lang.prefix}_secondary_message`]"
                      :label="`${lang.label} Secondary Message`"
                      outlined
                      rows="3"
                    ></v-textarea>
                  </div>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="create_dialog = false">Cancel</v-btn>
            <v-btn
              color="primary"
              text
              @click="saveAnnouncement"
              :loading="is_loading"
              :disabled="is_loading"
            >
              <v-progress-circular
                v-if="is_loading"
                indeterminate
                color="primary"
                size="20"
              ></v-progress-circular>
              <span v-else>Save</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Delete Dialog -->
      <v-dialog v-model="delete_dialog" width="500">
        <v-card>
          <v-card-title class="text-h6 grey lighten-2">Delete Announcement</v-card-title>
          <v-card-text>Are you sure you want to delete this announcement?</v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="delete_dialog = false">Cancel</v-btn>
            <v-btn
              color="primary"
              text
              @click="deleteAnnouncement"
              :loading="is_loading"
              :disabled="is_loading"
            >Confirm Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import { mdiSquareEditOutline, mdiTrashCanOutline } from '@mdi/js'

export default {
  data() {
    return {
      activeTab: 0,
      languages: [
        { label: 'English', prefix: 'en' },
        { label: 'Spanish', prefix: 'es' },
        { label: 'German', prefix: 'de' },
        { label: 'French', prefix: 'fr' },
        { label: 'Italian', prefix: 'it' },
        { label: 'Dutch', prefix: 'nl' },
      ],
      loading_data: false,
      is_loading: false,
      edit_mode: false,
      filters: {
        search_keyword: '',
        status: '',
      },
      headers: [
        { text: 'Title', value: 'en_title' },
        { text: 'Primary Message', value: 'en_primary_message' },
        { text: 'Secondary Message', value: 'en_secondary_message' },
        { text: 'Status', value: 'status' },
        { text: 'Actions', value: 'actions' },
      ],
      announcements: {
        current_page: 1,
        data: [],
      },
      announcement_data: {
        status: 1,
        id: null,
      },
      create_dialog: false,
      delete_dialog: false,
      content_status: [
        {name: 'Active', value: 1}, 
        {name: 'In-Active', value: 0}
      ],
      mdiSquareEditOutline,
      mdiTrashCanOutline,
    }
  },

  created() {
    // Initialize language-specific fields
    this.languages.forEach(lang => {
      this.announcement_data[`${lang.prefix}_title`] = ''
      this.announcement_data[`${lang.prefix}_primary_message`] = ''
      this.announcement_data[`${lang.prefix}_secondary_message`] = ''
    })
    this.fetchAnnouncements()
  },

  methods: {
    async fetchAnnouncements() {
      this.loading_data = true
      try {
        const response = await axios.get('admin/announcements', {
          params: {
            page: this.announcements.current_page,
            search: this.filters.search_keyword,
            status: this.filters.status,
          },
        })
        this.announcements = response.data?.data
      } catch (error) {
        console.error('Error fetching announcements:', error)
      }
      this.loading_data = false
    },

    async saveAnnouncement() {
      this.is_loading = true
      try {
        if (this.edit_mode) {
          await axios.put(`admin/announcements/${this.announcement_data.id}`, this.announcement_data)
        } else {
          await axios.post('admin/announcements', this.announcement_data)
        }
        this.create_dialog = false
        this.fetchAnnouncements()
      } catch (error) {
        console.error('Error saving announcement:', error)
      }
      this.is_loading = false
    },

    openEditDialog(id) {
      this.edit_mode = true
      const announcement = this.announcements.data.find(item => item.id === id)
      this.announcement_data = { 
        ...announcement,
        status: Number(announcement.status)
      }
      this.create_dialog = true
    },

    openDeleteDialog(id) {
      this.announcement_data.id = id
      this.delete_dialog = true
    },

    async deleteAnnouncement() {
      this.is_loading = true
      try {
        await axios.delete(`admin/announcements/${this.announcement_data.id}`)
        this.delete_dialog = false
        this.fetchAnnouncements()
      } catch (error) {
        console.error('Error deleting announcement:', error)
      }
      this.is_loading = false
    },

    resetForm() {
      this.announcement_data = {
        status: 1,
        id: null,
      }
      // Reset language-specific fields
      this.languages.forEach(lang => {
        this.announcement_data[`${lang.prefix}_title`] = ''
        this.announcement_data[`${lang.prefix}_primary_message`] = ''
        this.announcement_data[`${lang.prefix}_secondary_message`] = ''
      })
      this.activeTab = 0 // Reset to first tab
    },

    openCreateDialog() {
      this.edit_mode = false
      this.resetForm()
      this.create_dialog = true
    },
  },
}
</script> 